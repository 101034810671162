<template>
  <div>
    <v-app-bar flat color="transparent" class="main-appbar">
      <app-bar-nav-icon />
      <v-skeleton-loader
        type="chip"
        transition="fade-transition"
        v-if="loading"
      ></v-skeleton-loader>

      <v-toolbar-title v-else class="headline font-weight-bold"
        >Users ({{ listMeta.total }})</v-toolbar-title
      >

      <v-spacer></v-spacer>
      <v-row v-show="showSearch" align="center" style="max-width: 300px">
        <v-text-field
          class="search"
          ref="searchField"
          flat
          hide-details
          solo
          rounded
          clearable
          background-color="grey lighten-3"
          label="Search name, email or phone number"
          v-model="filter.search"
          @input="search"
        ></v-text-field>
      </v-row>

      <v-skeleton-loader
        type="actions"
        transition="fade-transition"
        v-if="loading"
      ></v-skeleton-loader>

      <template v-else>
        <v-btn class="ml-5" icon @click="showSearchField">
          <img src="@/assets/icons/search.svg" alt height="17.7px" />
        </v-btn>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              color="#383d3f"
              class="mr-1"
              icon
              @click="exportList"
              v-on="on"
            >
              <v-icon>{{ icons.export }}</v-icon>
            </v-btn>
          </template>
          <span>Export to Excel</span>
        </v-tooltip>
      </template>
    </v-app-bar>

    <div
      class="mt-12 mx-4"
      id="user-list"
      v-infinite-scroll="loadMore"
      :infinite-scroll-disabled="loading"
      infinite-scroll-distance="100"
    >
      <v-data-table
        must-sort
        hide-default-footer
        :multi-sort="false"
        @click:row="($event) => (!loading ? handleRowClick($event) : null)"
        @update:options="handleDataTableChange"
        :disabled="loading"
        :loading="loading"
        :headers="headers"
        :server-items-length="listMeta.total"
        :items="list"
      >
        <template slot="body.append" v-if="loading">
          <tr
            v-for="(loader, loaderIndex) in new Array(20)"
            :key="loaderIndex + '__LoaderIndex'"
          >
            <td
              :class="'text-' + header.align"
              v-for="header in headers"
              :key="header.value"
            >
              <v-skeleton-loader
                type="list-item"
                transition="fade-transition"
              />
            </td>
          </tr>
        </template>

        <template slot="item.first_name" slot-scope="{ item }">
          <div>
            <!-- v-if="!item.isGym" -->
            <template v-if="item.isGym && item.owned_gym">
              <user-avatar
                :avatar="item.owned_gym.logo"
                size="40"
                class="mr-4"
              />
            </template>
            <user-avatar v-else :avatar="item.avatar" size="40" class="mr-4" />

            <template v-if="item.isGym">
              <template v-if="item.owned_gym">
                {{ item.owned_gym.name }}
              </template>
            </template>
            <template v-else>
              {{ item.first_name }} {{ item.last_name }}
            </template>
          </div>
        </template>

        <template v-slot:item.roles.name="{ item }">
          <template v-if="item.roles">
            <v-chip
              color="success"
              v-for="role in item.roles"
              small
              v-bind:key="role + '__RoleKey'"
            >
              {{ role.name }}</v-chip
            >
          </template>
        </template>

        <template slot="item.created_at" slot-scope="{ item }">
          <div>
            {{ item.addedDate }}
          </div>
        </template>

        <template slot="item.blocked_at" slot-scope="{ item }">
          <div>
            <p v-if="!item.blocked_at" class="green--text mb-0">Active</p>
            <p v-if="item.blocked_at" class="red--text mb-0">Blocked</p>
          </div>
        </template>
      </v-data-table>
    </div>

    <v-snackbar v-model="snackbar.show" :color="snackbar.color" right>
      {{ snackbar.message }}
      <v-btn color="white" text @click="snackbar.show = false">Ok</v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import {
  mdiDotsVertical,
  mdiUnfoldMoreHorizontal,
  mdiMagnify,
  mdiAccountCircleOutline,
  mdiChevronRight,
  mdiFileExcel,
  mdiArrowUp,
  mdiArrowDown,
} from '@mdi/js'
import ControlsMixin from '@/utils/mixins/Controls'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import UserAvatar from '@/components/UserAvatar'
import infiniteScroll from 'vue-infinite-scroll'
import debounce from 'lodash/debounce'

export default {
  name: 'UsersPage',

  mixins: [ControlsMixin],

  directives: {
    infiniteScroll,
  },

  components: {
    UserAvatar,
    AppBarNavIcon,
  },

  data() {
    return {
      headers: [
        {
          text: 'Name',
          value: 'first_name',
          sortable: true,
        },
        {
          text: 'Role',
          value: 'roles.name',
          sortable: true,
        },
        {
          text: 'Status',
          align: 'right',
          value: 'blocked_at',
          sortable: true,
        },
        {
          text: 'Added At',
          align: 'right',
          value: 'created_at',
          sortable: true,
        },
      ],
      loading: false,
      showSearch: false,
      showProfile: false,
      selectedProfile: null,
      perPage: 20,
      filter: {
        sort: '-created_at',
        search: '',
      },
      icons: {
        settings: mdiDotsVertical,
        sort: mdiUnfoldMoreHorizontal,
        search: mdiMagnify,
        view: mdiAccountCircleOutline,
        arrow: mdiChevronRight,
        export: mdiFileExcel,
        arrowUp: mdiArrowUp,
        arrowDown: mdiArrowDown,
      },
      snackbar: {
        show: false,
        message: null,
        color: null,
      },
    }
  },

  created() {
    this.clearUsers()
  },

  computed: {
    ...mapState({
      list: (state) => state.user.list,
      listMeta: (state) => state.user.listMeta,
    }),

    paginationLength() {
      return (this.listMeta.total / this.listMeta.per_page) | 0
    },
  },

  methods: {
    ...mapActions({
      getUsers: 'user/getUsers',
      exportToExcel: 'user/exportToExcel',
    }),

    ...mapMutations({
      clearUsers: 'user/clearUserList',
      setListMeta: 'user/setUserListMeta',
    }),

    resetListMeta() {
      this.setListMeta({
        current_page: 0,
        last_page: 1,
      })
    },

    pageChanged(page) {
      this.fetchUsers(page)
    },

    filterUser(filterValue) {
      this.clearUsers()
      this.fetchUsers(null, filterValue)
    },

    loadMore() {
      if (this.listMeta.current_page < this.listMeta.last_page) {
        this.fetchUsers(this.listMeta.current_page + 1)
      }
    },

    search: debounce(function () {
      this.clearUsers()
      this.fetchUsers()
    }, 600),

    async fetchUsers(page = 1) {
      if (this.loading) return
      let params = {
        page,
        sort: this.filter.sort,
        perPage: this.perPage,
      }
      if (this.filter.search) {
        params.search = this.filter.search
      }
      this.loading = true
      await this.getUsers(params)
      this.loading = false
    },

    showProfileDialog(profile) {
      this.selectedProfile = profile
      this.showProfile = true
    },

    async exportList() {
      this.loading = true
      await this.exportToExcel()
        .then((response) => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.ms-excel',
            })
          )
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'users_list.xlsx')
          document.body.appendChild(link)
          link.click()
          this.showSnackbar('File successfully downloaded', 'success')
        })
        .catch(() => {
          this.showSnackbar('Ops! Something went wrong', 'red')
        })
      this.loading = false
    },

    showSnackbar(message, color) {
      this.snackbar.message = message
      this.snackbar.color = color
      this.snackbar.show = true
    },
    async showSearchField() {
      this.showSearch = !this.showSearch
      await this.$nextTick()
      this.$refs.searchField.$refs.input.focus()
    },
    handleDataTableChange({ sortBy, sortDesc }) {
      var prepend = sortDesc.length ? (sortDesc[0] ? '-' : '') : ''
      var sortKey = sortBy.length ? sortBy[0] : ''
      var sort = `${prepend}${sortKey}`
      this.perPage = 20
      this.filter.sort = sort !== '' ? sort : '-created_at'
      this.resetListMeta()
      this.clearUsers()
      this.fetchUsers(1)
    },
    handleRowClick($event) {
      this.$router.push({ name: 'user.details', params: { id: $event.id } })
    },
  },
}
</script>
